import React from 'react';

function Scheduling({ fill, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="3"
      y="4"
      width="18"
      height="16"
      rx="2"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <line
      x1="3"
      y1="8"
      x2="21"
      y2="8"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <circle
      cx="18"
      cy="18"
      r="4"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <line
      x1="18"
      y1="18"
      x2="20"
      y2="18"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="18"
      y1="18"
      x2="18"
      y2="16"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <line
      x1="18"
      y1="18"
      x2="18"
      y2="16"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
  );
}

export default Scheduling;