import { Button, ButtonProps, styled } from '@mui/material';

export const PrimaryButtonStyled = styled(Button, {
  name: 'PrimaryButton',
  slot: 'Root'
})<ButtonProps>(() => ({
  backgroundColor: '#0078C8',
  padding: '.56rem 1.75rem',
  borderRadius: '.75rem',
  border: '1px solid #DFE1E3',
  textTransform: 'none',
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: '#0B789B',
    border: '1px solid #0B789B'
  }
}));
