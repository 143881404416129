import React, { useState } from 'react';
import ReportWrraper from '@/components/report';
import { useNavigate } from 'react-router-dom';
import { EFORMAT_API, ReportFormat } from '@/entity';
import { OnFinishData } from '@/components/report/type';
import { usePutReportTemplateFileMutation } from '@/services/api/reportTemplate';
import { useCreateReport } from '@/hooks/useCreateReport';
import PageLoader from '@/components/PageLoader/PageLoader';
import useHandleFormApiErrors from '@/hooks/useHandleFormApiErrors';

function SelectReportTemplatePage(): React.JSX.Element {
  const [uploadReporttemplateFile] = usePutReportTemplateFileMutation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleFormApiErrors = useHandleFormApiErrors();

  const { onApply: onApplyCreateReport } = useCreateReport();

  const onApply = async (reqBody: OnFinishData, isGoogleSheet: ReportFormat) => {
    setIsLoading(true);
    const res = await onApplyCreateReport(reqBody, isGoogleSheet);
    setIsLoading(false);
    if (res) {
      navigate('/');
    }
  };

  return (
    <>
      <ReportWrraper
        onApplyGoogleSheets={form => onApply(form, EFORMAT_API.GOOGLE_SHEETS)}
        onApplyExcel={form => onApply(form, EFORMAT_API.EXCEL)}
      />
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: '#8080805c',
            top: '0',
            left: '0',
            zIndex: '50',
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <PageLoader />
        </div>
      )}
    </>
  );
}

export default SelectReportTemplatePage;
