import { Box, Tab, Tabs } from '@mui/material';
import WorkspaceModalTab from '@/components/manageWorkspaceMenu/workspaceModalTab';
import { ParameterValues } from '@/components/parameterValues';
import { useMemo, useState } from 'react';
import useSession from '@/hooks/useSession';
import { SelectReportingMethod } from '@/components/selectReportingMethod';
import { ExelIcon, SheetsIcon } from '@/components/icons';
import SelectReportingMethodExcel from '@/components/selectReportingMethod/selectReportingMethodExcel';
import { usePatchReportMutation } from '@/services';
import { TReportDb } from '@/entity';
import { TReportBody } from '@/services/api/reports/types';
import { formatISO } from 'date-fns';
import { SelectReportMethodForm } from '@/components/selectReportingMethod/SelectReportingMethod';
import { uploadReportTemplate } from '@/services/api/reportTemplate';
import { formationSchedule } from '@/pages/CreateNewReportPage/helpers';
import { DefaultIntervalValues, INTERVALS } from '@/components/selectReportingMethod/type';
import { prepareTemplateParameters } from '@/components/report/helpers';
import { EDIT_REPORT_MODAL_TABS } from '@/components/modal/EditReport/const';
import { EditReportModalTabs } from '@/components/modal/EditReport/types';
import { TemplateParameterWithValue } from '@/components/parameterValues/type';
import { ReportTemplateApiTab } from '@/services/api/reportTemplates/types';
import { useCreateReport } from '@/hooks/useCreateReport';
import {
  EMPTY_FIELD_IN_TEMPLATE_PARAMETER,
  PARAMETER_VALUES_DATE_KEYS_NAME,
  PARAMETER_VALUES_DATE_KEYS_NAME_ARRAY
} from '@/components/parameterValues/const';

interface EditReportModalProps {
  onClose?: () => void;
  report?: TReportDb;
  onFinishEdit?: () => void;
}

const MOCK_BODY: any = {
  startDate: '2024-07-05T12:36:49.161Z',
  endDate: '2024-07-05T12:36:49.161Z',
  deliveryFormat: 'SCHEDULED_SPREADSHEET'
};

const EditReportModal = ({ onClose, report, onFinishEdit }: EditReportModalProps) => {
  const { user, accessToken, currentWorkspaceId } = useSession();
  const { convertTabsValuesForRequest } = useCreateReport();
  const [pathcReport] = usePatchReportMutation();

  const [tabValue, setTabValue] = useState<EditReportModalTabs>(
    EDIT_REPORT_MODAL_TABS.PARAMETER_VALUES
  );
  const [isMicrofostExcel, setIsMicrofostExcel] = useState<boolean>(
    // report?.reportFormat === 'EXCEL'
    true
  );

  const styleConfigModal = {
    isNotMarginTop: true,
    isModalTemplate: true,
    isNotBorder: true,
    isNotHeaderTitle: true
  };
  const sxContainer = { maxHeight: '670px' };
  const tabLabel = isMicrofostExcel ? 'Microsoft Excel' : 'Google Sheets';

  const parametersValueRender = useMemo(() => {
    const prepareTemp = prepareTemplateParameters(
      report?.reportTemplate?.templateParameters || [],
      report?.reportParameters
    );
    if (
      !report?.reportParameters.find(
        item => item.name === PARAMETER_VALUES_DATE_KEYS_NAME.PERIOD_TYPE
      )
    ) {
      return prepareTemp;
    }

    const keysObject = {
      // eslint-disable-next-line no-unsafe-optional-chaining
      ...(report?.reportParameters ? report?.reportParameters : [])
        .filter(item => PARAMETER_VALUES_DATE_KEYS_NAME_ARRAY.includes(item.key as any))
        .reduce((acc: any, item) => {
          acc[item.key] = {
            ...EMPTY_FIELD_IN_TEMPLATE_PARAMETER,
            ...item
          };
          return acc;
        }, {})
    };

    return {
      ...prepareTemp,
      ...keysObject
    };
  }, [report]);

  const parametersTabValueRender = useMemo(() => {
    return report?.reportTabs.map((tab, index) => {
      return {
        ...tab,
        templateParametersValuesRender: prepareTemplateParameters(
          report?.reportTemplate?.reportTemplateApiTabs[index]?.templateParameters as any,
          (tab.reportParameters || []) as any
        )
      };
    }) as any[];
  }, [report]);

  const onHandleChangeTab = (event: React.SyntheticEvent, newValue: EditReportModalTabs) => {
    setTabValue(newValue);
  };

  const onApply = async (reqBody?: any, onSuccess?: () => void) => {
    const startDate = reqBody?.startDate ? reqBody?.startDate.toString() : MOCK_BODY.startDate;
    const endDate = reqBody?.endDate ? reqBody?.endDate.toString() : MOCK_BODY.endDate;

    const prepareBody: TReportBody = {
      ...MOCK_BODY,
      ...report,
      ...reqBody,
      companyIds: report?.companies?.map(company => company.id),
      name: reqBody?.reportName || report?.name,
      startDate: formatISO(new Date(startDate)),
      endDate: formatISO(new Date(endDate))
    };

    await pathcReport({ ...prepareBody, workspaceId: currentWorkspaceId });

    onSuccess && onSuccess();
  };

  const onHandlerApplyParametrValue = async (props: SelectReportMethodForm) => {
    if (!accessToken) {
      return;
    }

    const { period, daysOfMonth, daysOfWeek } = props;
    if (period === 'MONTHLY' || period === 'MONTHS') {
      if (!daysOfMonth.length) {
        return;
      }
    }
    if (period === 'WEEKS' || period === 'WEEKLY') {
      if (!daysOfWeek.length) {
        return;
      }
    }

    const schedule = formationSchedule(props);

    await onApply({ schedule: schedule, reportFormat: props.format });

    const reportTemplateId = report?.reportTemplate?.id;
    if (reportTemplateId && props.file) {
      await uploadReportTemplate(reportTemplateId, props.file, accessToken);
    }

    onFinishEdit && onFinishEdit();
    onClose && onClose();
  };

  const getDefaultInterval = (reportProps: TReportDb) => {
    const schedule = reportProps.schedule;
    let defaultInterval: DefaultIntervalValues = {
      selectInterval: INTERVALS.DAYS,
      hourOfDay: schedule.hourOfDay,
      dayOfWeek: [1],
      dayOfMonth: [1]
    };
    switch (schedule.period) {
      //////
      case INTERVALS.DAYS:
        defaultInterval.selectInterval = INTERVALS.DAILY;
        break;
      //////
      case INTERVALS.WEEKS:
        defaultInterval.selectInterval = INTERVALS.WEEKLY;
        defaultInterval.dayOfWeek = schedule.offsets;
        break;
      //////
      case INTERVALS.MONTHS:
        defaultInterval.selectInterval = INTERVALS.MONTHLY;
        defaultInterval.dayOfMonth = schedule.offsets;
        break;
      //////
      default:
        break;
    }

    return defaultInterval;
  };

  return (
    <Box
      sx={{
        width: 'auto',
        minWidth: '600px'
      }}
    >
      <Tabs value={tabValue} onChange={onHandleChangeTab} sx={{ width: '100%' }}>
        <Tab
          label="Report settings"
          id="manage-workspace-tab-0"
          aria-controls="manage-workspace-tabpanel-0"
          sx={{ width: '50%' }}
        />
        <Tab
          label={tabLabel}
          id="manage-workspace-tab-1"
          aria-controls="manage-workspace-tabpanel-1"
          sx={{ width: '50%' }}
        />
      </Tabs>
      <WorkspaceModalTab value={tabValue} index={0} sxContainer={sxContainer}>
        <Box>
          <ParameterValues
            defaultName={report?.name}
            defaulValue={{
              startDate: report?.startDate ? new Date(report?.startDate) : undefined,
              endDate: report?.endDate ? new Date(report?.endDate) : undefined
            }}
            styleConfig={{
              ...styleConfigModal,
              customDownButtons: [
                {
                  onClick: (values: any, parameters?: TemplateParameterWithValue[]) => {
                    onApply(
                      {
                        ...values.form,
                        reportParameters:
                          parameters?.map(({ name, value, dataType, key }) => ({
                            name,
                            value: value as string,
                            dataType,
                            key
                          })) || [],
                        reportTabs: convertTabsValuesForRequest(values.parameterValueTabs)
                      },
                      onFinishEdit
                    );
                  }
                }
              ]
            }}
            renderParameter={parametersValueRender}
            parametersTabsValuesRender={parametersTabValueRender}
          />
        </Box>
      </WorkspaceModalTab>
      <WorkspaceModalTab value={tabValue} index={1} sxContainer={sxContainer}>
        <Box>
          {isMicrofostExcel ? (
            <SelectReportingMethodExcel
              templateId={report?.reportTemplate?.id} /// TODO: change in realm
              company={report?.companies?.[0]}
              styleConfig={{
                ...styleConfigModal,
                customDownButtons: [
                  {
                    isOutline: true,
                    label: 'Switch to Google Sheets',
                    startIcon: <SheetsIcon />,
                    onClick: () => setIsMicrofostExcel(false),
                    disabled: true
                  },
                  {
                    onClick: onHandlerApplyParametrValue
                  }
                ]
              }}
              userName={user?.nickname || ''}
              email={user?.email || ''}
              avatarUrl={user?.picture || ''}
              defaultInterval={report ? getDefaultInterval(report) : undefined}
            />
          ) : (
            <SelectReportingMethod
              styleConfig={{
                ...styleConfigModal,
                customDownButtons: [
                  {
                    isOutline: true,
                    label: 'Switch to Microsoft Excel',
                    startIcon: <ExelIcon />,
                    onClick: () => setIsMicrofostExcel(true)
                  },
                  {
                    onClick: onHandlerApplyParametrValue
                  }
                ]
              }}
              userName={user?.nickname || ''}
              email={user?.email || ''}
              avatarUrl={user?.picture || ''}
              defaultInterval={report ? getDefaultInterval(report) : undefined}
            />
          )}
        </Box>
      </WorkspaceModalTab>
    </Box>
  );
};

export default EditReportModal;
