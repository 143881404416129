import { useCallback } from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { Auth0User } from '@/types';
import { INVITAION_TOKEN_KEY } from '@/hooks/useInviteTokenHandling';
import { useGetUserInfoMutation } from '@/services/api/user';
import { usePostWorkspaceMutation } from '@/services/api/workspaces';

const useGetPreparedUserInfo = () => {
  const { getUser } = useAuth();
  const [getUserInfo] = useGetUserInfoMutation();
  const [createWorkspace] = usePostWorkspaceMutation();

  return useCallback(async (accessToken: string) => {
    // eslint-disable-next-line no-debugger
    debugger;
    const auth0User: Auth0User = await getUser(accessToken);
    let userInfo: any = await getUserInfo({ accessToken });

    if (
      userInfo?.data?.data?.workspaces?.length === 0 &&
      !localStorage.getItem(INVITAION_TOKEN_KEY)
    ) {
      await createWorkspace({
        name: auth0User.nickname,
        accessToken
      });
      userInfo = await getUserInfo({ accessToken });
    }

    return { auth0User, userInfo };
  }, []);
};

export default useGetPreparedUserInfo;
