import Box from '@mui/material/Box';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import authSvg from '@/assets/icons/auth.svg';
import { OutlinedLightButton } from '@/components';
import ConnectFirstCompany from '@/components/ConnectFirstCompany';
import { useConnectToQuickBooks } from '@/hooks/useConnectToQuickBooks';
import useSession from '@/hooks/useSession';
import { setInitFlow } from '@/slices';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Paper } from '@mui/material';
import { useDispatch } from 'react-redux';

export default function ConnectCompanyPage(): React.JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { initFlow } = useSession();
  const onHandlerConnectQuickbook = useConnectToQuickBooks();

  React.useEffect(() => {
    if (initFlow?.isFirstConnect) {
      dispatch(setInitFlow({ isFirstConnect: false, isRedirectAfterConnectToReportCreate: true }));
    }
  }, []);

  return (
    <Box
      sx={{
        display: { xs: 'flex', lg: 'grid' },
        flexDirection: 'column',
        gridTemplateColumns: '1fr 1fr',
        minHeight: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'normal',
          color: 'var(--mui-palette-common-white)',
          display: { xs: 'none', lg: 'flex' },
          justifyContent: 'left',
          height: '100vh',
          width: '100%'
        }}
      >
        <img src={authSvg} style={{ height: '100%' }} alt="authSvg" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Paper
          sx={{
            maxWidth: '474px',
            mx: { xs: 2, sm: 'auto' },
            my: '50px',
            padding: '48px 32px 32px 32px',
            borderRadius: '12px',
            width: '100%'
          }}
          elevation={9}
        >
          <ConnectFirstCompany
            onConnectQuickbook={onHandlerConnectQuickbook}
            onLoginWithXero={() => console.log('loginWithXero')}
            actions={
              <OutlinedLightButton
                fullWidth
                label="Back"
                onClick={() => navigate('/')}
                sx={{
                  color: '#747A82'
                }}
                startIcon={
                  <ArrowBackIcon
                    sx={{
                      color: '#747A82'
                    }}
                  />
                }
              />
            }
          />
        </Paper>
      </Box>
    </Box>
  );
}
