import { quickbooksUserManager } from '@/services/oidcClient';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const ConnectToQuickBooksLauncher = () => {
  const navigate = useNavigate();

  useEffect(() => {
    quickbooksUserManager.signinRedirect();
  }, [navigate]);

  return <div>Connecting to QuickBooks...</div>;
};
