import React from 'react';
import { Stack, Typography } from '@mui/material';
import linkIcon from '@/assets/icons/link.svg';
import XeroIcon from '@/assets/images/Xero.png';
import QuickBooksButton from '../QuickBooksButton';
import AuthButton from '../auth/authButton/AuthButton';

type Props = {
  onConnectQuickbook: () => void;
  onLoginWithXero: () => void;
  actions?: React.ReactNode;
};

const ConnectFirstCompany = ({ actions, onConnectQuickbook, onLoginWithXero }: Props) => {
  return (
    <Stack gap="32px" sx={{ textAlign: 'center' }}>
      <Stack sx={{ display: 'flex', alignItems: 'center' }}>
        <img
          style={{
            width: '72px',
            height: '72px'
          }}
          src={linkIcon}
          alt="linkIcon"
        />
      </Stack>
      <Stack>
        <Typography
          sx={{ fontSize: { xs: '16px', sm: '24px' }, fontWeight: 700, lineHeight: '30px' }}
          gutterBottom
        >
          Connect your first company
        </Typography>
        <Typography
          justifyContent={'center'}
          sx={{ fontSize: { xs: '14px', sm: '16px' }, color: '#393A3DA3', fontWeight: 400 }}
        >
          Please select a company to continue
        </Typography>
      </Stack>
      <Stack gap="16px">
        <QuickBooksButton onClick={onConnectQuickbook} />
        <AuthButton
          onClick={onLoginWithXero}
          label="Connect to Xero"
          icon={XeroIcon}
          typographyStyle={{ color: 'white', fontSize: '14px' }}
          rootStyle={{
            backgroundColor: '#0B789B',
            '&:hover': { backgroundColor: '#0078C8' },
            cursor: 'not-allowed'
          }}
        />
        {actions}
      </Stack>
    </Stack>
  );
};

export default ConnectFirstCompany;
