import { BoxProps, styled } from '@mui/material';
import Box from '@mui/material/Box';

export const CombinedTable = styled(Box, {
  name: 'CombinedTable',
  slot: 'Root'
})<BoxProps>({
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem'
});

export const CombinedTableMain = styled(Box, {
  name: 'CombinedTableMain',
  slot: 'Root'
})<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

export const CombinedTableToolbar = styled(Box, {
  name: 'CombinedTableToolbar',
  slot: 'Root'
})<BoxProps>({});

export const CombinedTableBody = styled(Box, {
  name: 'CombinedTableBody',
  slot: 'Root'
})<BoxProps>({});

export const CombinedTableFooter = styled(Box, {
  name: 'CombinedTableFooter',
  slot: 'Root'
})<BoxProps>({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '1rem'
});

export const CombinedTableActions = styled(Box, {
  name: 'CombinedTableActions',
  slot: 'Root'
})<BoxProps>({
  display: 'flex',
  justifyContent: 'flex-end'
});
