import TableRow from '@mui/material/TableRow';
import { FC } from 'react';
import { Typography } from '@mui/material';
import { ConnectionTableRowStyled } from './connectionTableRow.styled';
import Stack from '@mui/material/Stack';
import { TConnectionsTableRowProps } from './connectionTableRow.types';

const ConnectionTableRow: FC<TConnectionsTableRowProps> = ({
  item,
  actionsList,
  onTableRowClick,
  exceptCell = [],
  selected
}) => {
  const tableCellsMarkup = Object.entries(item)
    .filter(item => item[0] !== 'reportNumber')
    .map(([key, value]) => {
      const onAction = () => onTableRowClick && onTableRowClick(item.id);
      if (exceptCell.find(cell => cell === key)) {
        return <></>;
      }

      return (
        <ConnectionTableRowStyled key={key} onClick={onAction} sx={{ cursor: 'pointer' }}>
          <Typography variant="subtitle2">{value || '——'}</Typography>
        </ConnectionTableRowStyled>
      );
    });

  return (
    <TableRow hover selected={selected}>
      {tableCellsMarkup}
      {!!actionsList.length && (
        <ConnectionTableRowStyled>
          <Stack direction="row" spacing="1rem" justifyContent="center">
            {actionsList}
          </Stack>
        </ConnectionTableRowStyled>
      )}
    </TableRow>
  );
};

export default ConnectionTableRow;
