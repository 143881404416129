import AuthWrapper from '@/components/auth/AuthWrapper';
import SignupForm from '@/components/auth/SignupForm';
import useHandleFormApiErrors from '@/hooks/useHandleFormApiErrors';
import { paths } from '@/utils/paths';
import { Box, Link as MUILink, Typography } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const defaultSignUpErrorMessage =
  'Something went wrong while creating your account. Please contact support.';

type Props = {
  onSignUp: (email: string, password: string) => Promise<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  showMessage?: boolean;
};

const SignUpBody = ({ onSignUp, showMessage = true }: Props) => {
  const handleFormApiErrors = useHandleFormApiErrors();
  const [submitted, setSubmitted] = useState(false);

  return submitted ? (
    <Box sx={{ textAlign: 'center', p: 2 }}>
      <Typography sx={{ fontSize: { xs: '14px', sm: '16px' }, mb: 1 }}>
        A verification email was sent to your email inbox. Please verify your email address.
      </Typography>
      <Typography sx={{ fontSize: { xs: '14px', sm: '16px' }, mb: 2 }}>
        You can close this tab now or return to the login.
      </Typography>
    </Box>
  ) : (
    <>
      <Typography variant="h4" sx={{ textAlign: 'center' }} gutterBottom>
        Sign up
      </Typography>
      <Typography justifyContent={'center'} sx={{ fontSize: { xs: '14px', sm: '16px' } }}>
        Already have an account?{' '}
        <Link to={paths.auth.signIn} style={{ boxShadow: 'none' }}>
          <MUILink sx={{ textDecoration: 'none', color: '#0078C8', fontWeight: 600 }}>
            Log in
          </MUILink>
        </Link>
      </Typography>
      <AuthWrapper isSignUp>
        <SignupForm
          onSignUp={onSignUp}
          defaultSignUpErrorMessage={defaultSignUpErrorMessage}
          handleFormApiErrors={handleFormApiErrors}
          submitCallback={() => showMessage && setSubmitted(true)}
        />
      </AuthWrapper>
    </>
  );
};

export default SignUpBody;
