/* eslint-disable camelcase */
import { UserManagerSettings } from 'oidc-client-ts';

const BASE_URL = 'https://app.stage.clearsheets.io'

export const oidcConfig: UserManagerSettings = {
    authority: 'https://oauth.platform.intuit.com/op/v1', // Авторизаційний URL від Intuit
    client_id: 'ABe35e2ULxS3Tn3MOWZebDMBggZVvTgHiSJ0ZrroYHw6yi090L', // Замініть на ваш Client ID
    redirect_uri: `${BASE_URL}/auth-callback`, // URL перенаправлення після автентифікації
    response_type: 'code', // Використовуйте 'code' для безпечнішої автентифікації
    scope: 'openid profile email com.intuit.quickbooks.accounting', // Запитувані області
    post_logout_redirect_uri: BASE_URL,
    automaticSilentRenew: true,
    silent_redirect_uri: `${BASE_URL}/silent-renew`,

    metadata: {
        issuer: 'https://oauth.platform.intuit.com/op/v1',
        authorization_endpoint: 'https://appcenter.intuit.com/connect/oauth2',
        token_endpoint: 'https://oauth.platform.intuit.com/oauth2/v1/tokens/bearer',
        userinfo_endpoint: 'https://accounts.intuit.com/v1/openid_connect/userinfo',
        jwks_uri: 'https://oauth.platform.intuit.com/op/v1/jwks',
        end_session_endpoint: 'https://appcenter.intuit.com/Disconnect/Session',
    },
};