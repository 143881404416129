import { Box, Paper, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { config } from '@/config';
import SignUpBody from '@/components/auth/SignUpBody';
import { useNavigate } from 'react-router-dom';
import { paths } from '@/utils/paths';

const SignupStep1Page = () => {
  const navigate = useNavigate();

  const signUp = async (email: string, password: string) => {
    navigate(paths.invitation.signupStep2, { state: { email, password } });
  };

  return (
    <Box
      sx={{
        paddingX: { xs: 0, sm: '82px' },
        paddingY: { xs: 2, sm: '72px' }
      }}
    >
      <Box sx={{ mx: { xs: 2, sm: 0 } }}>
        <Typography variant="h5">ClearSheets</Typography>
      </Box>
      <Paper
        sx={{
          maxWidth: '474px',
          mx: { xs: 2, sm: 'auto' },
          my: '50px',
          padding: '48px 32px 32px 32px',
          borderRadius: '12px'
        }}
        elevation={9}
      >
        <Helmet>
          <title>Invitation | {config.site.name}</title>
        </Helmet>
        <Stack gap="32px" sx={{ textAlign: 'center' }}>
          <SignUpBody onSignUp={signUp} showMessage={false} />
        </Stack>
      </Paper>
    </Box>
  );
};

export default SignupStep1Page;
