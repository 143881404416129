import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '@/contexts/AlertContext';
import { Auth0Session } from '@/types';
import { useGetUserInfoByEmailMutation } from '@/services/api/user';
import { paths } from '@/utils/paths';

const useCheckUserExists = () => {
  const navigate = useNavigate();
  const { setAlert } = useAlert();
  const [getUserInfoByEmail] = useGetUserInfoByEmailMutation();

  return useCallback(async (authResult: Auth0Session) => {
    if (!authResult?.idTokenPayload?.email) {
      return false;
    }

    const existingUser: any = await getUserInfoByEmail({
      email: authResult?.idTokenPayload?.email,
      accessToken: authResult.accessToken
    });

    if (existingUser?.data?.data?.externalUserId !== authResult.idTokenPayload.sub) {
      setAlert({
        description: 'This email is already associated with another user',
        type: 'error'
      });
      navigate(paths.home);
      return true;
    }

    return false;
  }, []);
};

export default useCheckUserExists;
