import { ConnectionsPageContainer } from '@/containers';
import { CompanyRowI } from '@/hooks/companyService/type';

interface Props {
  onNext: (connection: CompanyRowI) => void;
  onBack?: () => void;
}

export const ConnectionForCreateReport = ({ onNext, onBack }: Props) => {
  return (
    <>
      <ConnectionsPageContainer
        exceptCell={['id', 'actions']}
        actionsList={[]}
        columns={[
          {
            label: 'Company name',
            accessor: 'companyName',
            sortable: false,
            props: { width: 350 }
          },
          { label: 'Platform', accessor: 'platform', sortable: true, props: { width: 350 } },
          { label: 'Created at', accessor: 'created', sortable: true, props: { width: 435 } }
        ]}
        onNext={onNext}
      />
      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '10px'
        }}
      >
        <OutlinedLightButton
          label="Back"
          startIcon={
            <ArrowBackIcon
              sx={{
                color: '#747A82'
              }}
              onClick={onBack}
            />
          }
        />
      </Box> */}
    </>
  );
};
